@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* MUI override */
.MuiInputBase-root {
  background-color: #fff !important;
}
.MuiInputBase-root {
  background: transparent !important;
}
.MuiDataGrid-cell {
  cursor: default;
}
.MuiDataGrid-cell > * {
  color: #555 !important;
}
.MuiDataGrid-cell--editable > * {
  color: #282c34 !important;
  font-weight: 700 !important;
  font-style: italic;
}
.MuiDataGrid-cell--editable {
  cursor: text;
}

// Input
// .MuiInputBase-input{
//   padding-top: 10px !important;
//   padding-bottom: 10px !important;
// }

// Grid
.MuiDataGrid-main{
  .MuiDataGrid-columnHeaders{
    @apply bg-primary-500 text-white font-bold;
    .MuiDataGrid-pinnedColumnHeaders{
      @apply bg-primary-500 text-white;
    }
  }
  .MuiDataGrid-row{
    &:nth-child(odd){
      @apply bg-primary-200;
    }
  }
}
